body {
  background-color: #d8d8d8;
  font-family: 'Nunito', sans-serif;
}

header {
  top: 0;
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #707070;
  z-index: 10;
}

#WebLogo {
  height: 40px;
  padding: 10px;
}

#WebLogo:hover {
  background-color: #929292;
}

#WebLogo:active {
  background-color: #646464;
}

.Page {
  padding-left: 20px;
  padding-right: 20px;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  user-select: none;
}

.Footer {
  background-color: #e9e9e9;
  text-align: center;
}

a {
  text-decoration: none;
  color: #000000;
}
a:active {
  text-decoration: none;
  color: #000000;
}

.HeaderMenu {
  display: inline-flex;
  position: absolute;
  top: 10px;
  cursor: pointer;
  user-select: none;
}

.HeaderMenuButtons {
  padding: 9px;
  margin-left: 10px;
}

.HeaderMenuButtons:hover {
  background-color: #7e7e7e; 
}

h1 {
  font-size: 30px;
}

.sidebarOpener {
  position: fixed;
  margin-top: -25px;
  font-size: 15px;
  border-radius: 0px 10px 10px 0px;
  border-color: #000000;
  border-style: solid;
  padding: 5px;
  height: 20px;
  width: 35px;
  text-align: center;
  background-color: #707070;
  color: #FFFFFF;
  font-weight: bolder;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  width: 260px;
  background-color: #9e9e9e;
  margin-top: -25px;
}

#sidebar-menu {
  padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    background-color: #b7b7b7;
    border-style: solid;
    border-radius: 6px;
    border-color: #747474;
    width: 190px;
    margin-left: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

#sidebar-menu-cont {
  list-style-type:none;
  margin-left: -40px;
}

#sidebar-menu:hover {
  background-color: #787878;
}

#sidebar-close {
  position: absolute;
  right: 0;
  font-size: 20px;
  padding: 10px;
  color: #940000;
  user-select: none;
  height: 20px;
  cursor: pointer;
}

#sidebar-menu-title {
  margin-left: 10px;
  user-select: none;
}

blockquote {
  background-color: #bcbcbc;
  margin-left: 2px;
  padding: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 10px;
  overflow-x: scroll;
  width: 70%;
}

code {
  background-color: #bcbcbc;
  padding: 2px;
  border-radius: 5px;
}

pre {
  width: 70%;
}

pre code {
  display: block;
  overflow-x: scroll;
  padding: 8px;
}

table {
  border-style: solid;
  background-color: #c7c7c7;
  border-radius: 5px;
}
th {
  padding: 10px;
  padding-right: 15px;
  border-bottom: solid #000000;
  border-right: solid #000000;
  background-color: #a8a8a8;
}
td {
  padding: 5px;
  border-right: solid #000000;
  border-bottom: solid #000000;
}